"use client";

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { OrderNowMenuProvider } from "@/context/OrderNowMenuContext";

const queryClient = new QueryClient();

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <OrderNowMenuProvider>{children}</OrderNowMenuProvider>
    </QueryClientProvider>
  );
};

export default Providers;
