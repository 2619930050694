import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/lib/localFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/GibsonRegular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/GibsonSemiBold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./fonts/GibsonBlack.woff\",\"weight\":\"900\",\"style\":\"normal\"}],\"display\":\"block\",\"variable\":\"--font-gibson\",\"preload\":true}],\"variableName\":\"Gibson\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/lib/localFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/FloodStd.woff\",\"weight\":\"400\",\"style\":\"normal\",\"variable\":\"--font-flood-std\",\"preload\":false}],\"variableName\":\"FloodStd\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/lib/localFonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/CalgaryScriptOT.woff\",\"weight\":\"400\",\"style\":\"normal\",\"variable\":\"--font-calgary-script-std\",\"preload\":false}],\"variableName\":\"CalgaryScriptOT\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/ClientAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/Header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/HeaderBackground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/order-now/OrderNowMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/locations/LocationsDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/Chevron.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/Close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/FacebookLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/InstagramLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/LocationIcon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/ReburgerLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/svgs/ReburgerMarkLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/typography/Typography.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.scss");
