"use client";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import styles from "./HeaderBackground.module.scss";

const HeaderBackground = () => {
  const pathname = usePathname();

  const isHomePage = pathname === "/";

  const classes = classNames(styles.headerBackground, {
    [styles.opaque]: isHomePage,
  });

  return <div className={classes} />;
};

export default HeaderBackground;
