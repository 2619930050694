"use client";

import { useRef } from "react";
import { useOrderNowMenu } from "@/context/OrderNowMenuContext";
import LocationsMegaMenu from "@/components/locations/LocationsMegaMenu";
import Button from "@/components/button/Button";
import styles from "./OrderNowMenu.module.scss";

const OrderNowMenu = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { toggleMenu } = useOrderNowMenu();

  return (
    <div className={styles.container}>
      <Button
        ref={buttonRef}
        className={styles.orderNowButton}
        label="Order Now"
        onClick={toggleMenu}
        variant="elevated"
        compact
      />
      <LocationsMegaMenu />
    </div>
  );
};

export default OrderNowMenu;
