"use client";

import { useState } from "react";
import classNames from "classnames";
import styles from "./LocationsDrawerItems.module.scss"; // Import the SCSS file

import { useLocations } from "@/hooks/useLocations";
import { useOrderNowMenu } from "@/context/OrderNowMenuContext";

import { Location } from "types/location";

export type LocationGroup = {
  title: string;
  locations: Location[];
};

import { mapLocationsToGroups, qJumperOrderLocationHref } from "./_utils";

import { ChevronIcon } from "../svgs";
import Link from "next/link";

export default function LocationsDrawerItems() {
  const { data, isLoading } = useLocations();
  const { isMenuOpen } = useOrderNowMenu();

  const [expandedGroupIndex, setExpandedGroupIndex] = useState<number | null>(
    null
  );

  const toggleGroup = (index: number) => {
    setExpandedGroupIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  if (isLoading) {
    return <div></div>;
  }

  if (!data) {
    return <div></div>;
  }

  const { data: locations = [] } = data;

  const locationGroups = mapLocationsToGroups(locations);

  return (
    <ul className={styles.locationGroupsContainer}>
      {locationGroups.map((group, index) => (
        <LocationsGroupListItem
          key={index}
          group={group}
          isExpanded={expandedGroupIndex === index}
          onToggle={() => toggleGroup(index)}
          isMenuOpen={isMenuOpen}
        />
      ))}
    </ul>
  );
}

const LocationsGroupListItem: React.FC<{
  group: LocationGroup;
  isMenuOpen: boolean;
  isExpanded: boolean;
  onToggle: () => void;
}> = ({ group, isMenuOpen, isExpanded, onToggle }) => {
  return (
    <li className={styles.locationGroup}>
      <button
        onClick={onToggle}
        aria-expanded={isExpanded}
        className={classNames(styles.groupButton, {
          [styles.groupButtonActive]: isExpanded,
        })}
        disabled={!isMenuOpen}
        aria-disabled={!isMenuOpen}
      >
        {group.title}
        <ChevronIcon
          className={classNames(styles.groupIcon, {
            [styles.groupIconActive]: isExpanded,
          })}
        />
      </button>
      <LocationsList isExpanded={isExpanded} locations={group.locations} />
    </li>
  );
};

const LocationsList: React.FC<{
  isExpanded: boolean;
  locations: Location[];
}> = ({ isExpanded, locations }) => {
  const itemHeight = parseInt(styles.itemHeight);

  return (
    <ul
      className={classNames(styles.locationList, {
        [styles.expanded]: isExpanded,
      })}
      style={{
        height: isExpanded ? `${locations.length * itemHeight}px` : 0,
      }}
    >
      {locations.map((location) => (
        <LocationItem
          key={location.urlSegment}
          name={location.name}
          disabled={!isExpanded}
          href={qJumperOrderLocationHref(location.urlSegment)}
        />
      ))}
    </ul>
  );
};

const LocationItem: React.FC<{
  name: string;
  href: string;
  disabled: boolean;
}> = ({ href, name, disabled }) => {
  return (
    <li className={styles.locationItem}>
      <Link
        href={href}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : undefined}
        aria-label={`Order from RE Burger ${name}`}
      >
        {name}
      </Link>
    </li>
  );
};
