"use client";

import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./Drawer.module.scss";

import { CloseIcon } from "../svgs";

interface DrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleDrawer, children }) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  // const [isMobile, setIsMobile] = useState(false);

  // const checkMobile = () => {
  //   setIsMobile(window.matchMedia("(max-width: 768px)").matches);
  // };

  // useEffect(() => {
  //   // Run the mobile check initially and on window resize
  //   checkMobile();
  //   window.addEventListener("resize", checkMobile);

  //   return () => window.removeEventListener("resize", checkMobile);
  // }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;
    const bodyElement = document.body;
    if (isOpen) {
      htmlElement.classList.add("no-scroll");
      bodyElement.classList.add("no-scroll");
      const closeBtn = closeButtonRef.current;
      closeBtn?.focus();
    } else {
      htmlElement.classList.remove("no-scroll");
      bodyElement.classList.remove("no-scroll");
    }
    return () => {
      htmlElement.classList.remove("no-scroll");
      bodyElement.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <>
      {/* Drawer Overlay */}
      <div
        className={classNames(styles.overlay, { [styles.open]: isOpen })}
        onClick={toggleDrawer}
      />

      {/* Drawer */}
      <div className={classNames(styles.drawer, { [styles.open]: isOpen })}>
        <button
          ref={closeButtonRef}
          className={styles.closeButton}
          onClick={toggleDrawer}
          disabled={!isOpen}
        >
          <CloseIcon height={10} />
        </button>
        <div className={styles.contents}>{children}</div>
      </div>
    </>
  );
};

export default Drawer;

// "use client";

// import React, { useEffect, useState } from "react";
// import classNames from "classnames";
// import styles from "./Drawer.module.scss";
// import { CloseIcon } from "../svgs";

// interface DrawerProps {
//   isOpen: boolean;
//   toggleDrawer: () => void;
//   children: React.ReactNode;
// }

// const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleDrawer, children }) => {
//   const [isMobile, setIsMobile] = useState(false);

//   // Function to check if we are on a mobile device
//   const checkMobile = () => {
//     setIsMobile(window.matchMedia("(max-width: 768px)").matches);
//   };

//   useEffect(() => {
//     // Run the mobile check initially and on window resize
//     checkMobile();
//     window.addEventListener("resize", checkMobile);

//     return () => window.removeEventListener("resize", checkMobile);
//   }, []);

//   useEffect(() => {
//     const htmlElement = document.documentElement;
//     const bodyElement = document.body;

//     if (isOpen && isMobile) {
//       htmlElement.classList.add("no-scroll");
//       bodyElement.classList.add("no-scroll");

//       // Focus trap logic for mobile
//       const focusableElements = document.querySelectorAll(
//         'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
//       );

//       focusableElements.forEach((el) => {
//         if (!document.querySelector(`.${styles.drawer}`)?.contains(el)) {
//           (el as HTMLElement).setAttribute("tabindex", "-1");
//         }
//       });

//       // Focus first element inside the drawer
//       const firstFocusable = document.querySelector(
//         `.${styles.drawer} a, .${styles.drawer} button`
//       );
//       (firstFocusable as HTMLElement)?.focus();
//     } else {
//       // Remove no-scroll and restore tabIndex outside the drawer
//       htmlElement.classList.remove("no-scroll");
//       bodyElement.classList.remove("no-scroll");

//       const focusableElementsOutside = document.querySelectorAll(
//         `[tabindex='-1']:not(.${styles.drawer} a):not(.${styles.drawer} button)`
//       );
//       focusableElementsOutside.forEach((el) => {
//         (el as HTMLElement).removeAttribute("tabindex");
//       });
//     }

//     return () => {
//       htmlElement.classList.remove("no-scroll");
//       bodyElement.classList.remove("no-scroll");

//       // Restore focusability of elements when drawer closes
//       const focusableElements = document.querySelectorAll("[tabindex='-1']");
//       focusableElements.forEach((el) => {
//         (el as HTMLElement).removeAttribute("tabindex");
//       });
//     };
//   }, [isOpen, isMobile]);

//   return (
//     <>
//       {/* Drawer Overlay */}
//       <div
//         className={classNames(styles.overlay, { [styles.open]: isOpen })}
//         onClick={toggleDrawer}
//       />

//       {/* Drawer */}
//       <div className={classNames(styles.drawer, { [styles.open]: isOpen })}>
//         <button
//           className={styles.closeButton}
//           onClick={toggleDrawer}
//           disabled={!isOpen}
//           aria-disabled={!isOpen}
//         >
//           <CloseIcon height={10} />
//         </button>
//         <div className={styles.contents}>{children}</div>
//       </div>
//     </>
//   );
// };

// export default Drawer;
