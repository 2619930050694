import { useQuery } from "@tanstack/react-query";
import { getLocations } from "@/actions/getLocations";

export function useLocations() {
  return useQuery({
    queryKey: ["locations"],
    queryFn: () => getLocations(),
    retry: 2,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
  });
}
