import { Location } from "types/location";

export const qJumperOrderLocationHref = (segment: string) =>
  `https://order.qjumper.co.nz/#/reburger/${segment}`;

export type LocationGroup = {
  title: string;
  locations: Location[];
};

export function mapLocationsToGroups(locations: Location[]): LocationGroup[] {
  const groupMap = locations.reduce((acc, location) => {
    if (!acc[location.group]) {
      acc[location.group] = [];
    }
    acc[location.group].push(location);
    return acc;
  }, {} as { [key: string]: Location[] });

  return Object.entries(groupMap)
    .map(([group, locations]) => ({
      title: group,
      locations,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
}
