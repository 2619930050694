"use client";

import React from "react";

import { useOrderNowMenu } from "@/context/OrderNowMenuContext";
import Drawer from "../drawer/Drawer";
import LocationsDrawerItems from "./LocationsDrawerItems";
import styles from "./LocationsDrawer.module.scss";

const LocationsDrawer: React.FC = () => {
  const { isMenuOpen, toggleMenu } = useOrderNowMenu();

  return (
    <Drawer isOpen={isMenuOpen} toggleDrawer={toggleMenu}>
      <button
        className={styles.orderNowButton}
        onClick={toggleMenu}
        disabled={!isMenuOpen}
      >
        ORDER NOW
      </button>
      <div className={styles.contents}>
        <LocationsDrawerItems />
      </div>
    </Drawer>
  );
};

export default LocationsDrawer;
