import React from "react";
import styles from "./Typography.module.scss";
import classNames from "classnames"; // todo swap for clsx

type AllowedTags = "h1" | "h2" | "h3" | "h4" | "p";

type TypographyProps = {
  tag?: AllowedTags;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "body1"
    | "body2"
    | "subheading"
    | "caption";
  className?: string;
  gutterBottom?: boolean;
  disableMargin?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  align?: "left" | "center" | "right";
} & JSX.IntrinsicElements[AllowedTags];

const Typography: React.FC<TypographyProps> = ({
  tag: Tag = "p",
  variant = "body1",
  className = "",
  gutterBottom = false,
  disableMargin = false,
  children,
  style,
  align = "left",
  ...rest
}) => {
  return (
    <Tag
      className={classNames(styles[variant], className, {
        [styles.gutterBottom]: gutterBottom,
        [styles.alignCenter]: align === "center",
        [styles.alignRight]: align === "right",
        [styles.disableMargin]: disableMargin,
      })}
      style={style}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Typography;
