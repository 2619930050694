"use client";

import { createContext, useContext, useState, ReactNode } from "react";

interface OrderNowMenuContextType {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const OrderNowMenuContext = createContext<OrderNowMenuContextType | undefined>(
  undefined
);

// ToDo: maybe move these to seperate files
export const OrderNowMenuProvider = ({ children }: { children: ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <OrderNowMenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
      {children}
    </OrderNowMenuContext.Provider>
  );
};

export const useOrderNowMenu = () => {
  const context = useContext(OrderNowMenuContext);
  if (!context) {
    throw new Error(
      "useOrderNowMenu must be used within a OrderNowMenuProvider"
    );
  }
  return context;
};
