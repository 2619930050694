import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import classNames from "classnames";

import { useOrderNowMenu } from "@/context/OrderNowMenuContext";
import { useLocations } from "@/hooks/useLocations";
import styles from "./LocationsMegaMenu.module.scss";

import { mapLocationsToGroups, qJumperOrderLocationHref } from "./_utils";
import { ChevronIcon } from "../svgs";
import Typography from "../typography/Typography";
import Link from "next/link";

const LocationsMegaMenu: React.FC = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const { isMenuOpen, toggleMenu } = useOrderNowMenu();
  const { data, isLoading, error } = useLocations();

  const [expandedGroupIndex, setExpandedGroupIndex] = useState<number | null>(
    null
  );

  const toggleGroup = useCallback((index: number) => {
    setExpandedGroupIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const expandGroup = useCallback((index: number) => {
    setExpandedGroupIndex(index);
  }, []);

  const closeMenu = useCallback(() => {
    setExpandedGroupIndex(null);
    toggleMenu();
  }, [toggleMenu]);

  // Close the menu when clicking outside
  useEffect(() => {
    if (!isMenuOpen) return;

    const isDesktop = window.matchMedia("(min-width: 769px)").matches;
    if (!isDesktop) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    closeButtonRef.current?.focus();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, closeMenu]);

  const locationGroups = useMemo(
    () => mapLocationsToGroups(data?.data || []),
    [data]
  );

  const headerHeight = parseInt(styles.headerHeight);
  const locationItemHeight = parseInt(styles.locationItemHeight);
  const groupItemHeight = parseInt(styles.groupItemHeight);

  const groupsSpaceNeeded =
    headerHeight + locationGroups.length * groupItemHeight - 2;
  const locationsSpaceNeeded =
    expandedGroupIndex != null
      ? headerHeight +
        locationGroups[expandedGroupIndex].locations.length * locationItemHeight
      : 0;

  const spaceNeeded = useMemo(() => {
    return (
      Math.max(groupsSpaceNeeded, locationsSpaceNeeded) +
      (isLoading ? 50 : 0) +
      (error ? 50 : 0)
    );
  }, [groupsSpaceNeeded, locationsSpaceNeeded, isLoading, error]);

  const classes = useMemo(
    () =>
      classNames(styles.megaMenu, {
        [styles.open]: isMenuOpen,
        [styles.groupOpen]: expandedGroupIndex != null,
      }),
    [isMenuOpen, expandedGroupIndex]
  );

  const locationsClasses = useMemo(
    () =>
      classNames(styles.locations, {
        [styles.locationsOpen]: expandedGroupIndex != null,
      }),
    [expandedGroupIndex]
  );

  return (
    <div
      ref={menuRef}
      className={classes}
      style={{ height: isMenuOpen ? `${spaceNeeded}px` : 0 }}
    >
      <button
        ref={closeButtonRef}
        className={styles.menuToggle}
        onClick={closeMenu}
        disabled={!isMenuOpen}
        aria-disabled={!isMenuOpen}
        autoFocus={isMenuOpen}
      >
        ORDER NOW
      </button>

      <div className={styles.menuContent}>
        {isLoading && <Typography>Loading Locations</Typography>}
        {error && <Typography>Error Loading Locations</Typography>}
        <ul className={styles.groups}>
          {locationGroups.map((group, index) => (
            <button
              key={group.title}
              className={classNames(styles.groupItem, {
                [styles.groupItemSelected]: index === expandedGroupIndex,
              })}
              onClick={() => toggleGroup(index)}
              onMouseEnter={() => expandGroup(index)}
              disabled={!isMenuOpen}
            >
              {group.title}
              <ChevronIcon className={styles.groupItemIcon} />
            </button>
          ))}
        </ul>

        <ul className={locationsClasses}>
          {expandedGroupIndex != null &&
            locationGroups[expandedGroupIndex].locations.map((location) => (
              <li key={location.id}>
                <Link
                  href={qJumperOrderLocationHref(location.urlSegment)}
                  tabIndex={isMenuOpen ? undefined : -1}
                  aria-label={`Order from RE Burger ${location.name}`}
                >
                  {location.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default LocationsMegaMenu;
