import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

type ButtonVariant = "text" | "normal" | "elevated";

interface ButtonProps {
  label: string;
  endAdornment?: React.ReactNode;
  variant?: ButtonVariant;
  compact?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & JSX.IntrinsicElements["button"]
>((props, ref) => {
  const {
    label,
    endAdornment,
    variant = "normal",
    compact = false,
    selected = false,
    onClick,
    className,
    ...rest
  } = props;
  const classes = classNames(styles[variant], className, {
    [styles.compact]: compact,
    [styles.selected]: selected,
  });

  return (
    <button
      ref={ref}
      className={classes}
      onClick={onClick ?? undefined}
      aria-label={label}
      {...rest}
    >
      {label}
      {endAdornment}
    </button>
  );
});

Button.displayName = "Button";

export default Button;
