import ReactGA from "react-ga4";

export const initGA = () => {
  const measurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }
};

export const logPageView = (url: string) => {
  ReactGA.send({ hitType: "pageview", page: url });
};
