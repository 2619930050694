"use client";

import classNames from "classnames";
import Link from "next/link";

import { usePathname } from "next/navigation";
import styles from "./Navbar.module.scss";

type NavLink = {
  href: string;
  label: string;
};

const navLinks: NavLink[] = [
  { href: "/about", label: "About" },
  { href: "/contact", label: "Contact" },
];

const Navbar = () => {
  const pathname = usePathname();

  return (
    <nav className={styles.navbar}>
      <div className={styles.navLinks}>
        {navLinks.map(({ href, label }) => {
          const linkClasses = classNames(styles.link, {
            [styles.activeLink]: pathname === href,
          });
          return (
            <Link href={href} key={href} className={linkClasses}>
              {label}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
